<template>
	<div class="row">
		<LoadingSpinner v-if="loadingStock"/>
		<div v-else class="col-12">
			<h4 v-if="stallion">{{ $t('monte.stock_semence.utilisation_for') }} {{ stallion.horse_nom }} X {{ mare.horse_nom }}</h4>

			<table class="table table-variante mt-4">
				<tr v-if="stocks_used.length > 0">
					<th>{{ $t('monte.stock_semence.used') }}</th>
					<th>{{ $t('monte.stock_semence.numero_lot') }}</th>
					<th>{{ $t('monte.stock_semence.couleur') }}</th>
					<th>{{ $t('monte.stock_semence.localisation') }}</th>
					<th>{{ $t('monte.stock_semence.annuler') }}</th>
				</tr>
				<tr v-for="stock in stocks_used" :key="stock.uid">
					<td>{{ stock.stock }}</td>
					<td>{{ stock.lot }}</td>
					<td>{{ $t('monte.couleur.' + stock.couleur.semencecouleur_code) }}</td>
					<td>{{ stock.localisation }}</td>
					<td>
						<div class="form-group">
							<b-form-checkbox
	                            v-model="stock.cancel"
	                        >
	                        </b-form-checkbox>
						</div>
					</td>
				</tr>

				<tr v-if="stocks.length > 0">
					<th>{{ $t('monte.stock_semence.dispo') }}</th>
					<th>{{ $t('monte.stock_semence.numero_lot') }}</th>
					<th>{{ $t('monte.stock_semence.couleur') }}</th>
					<th>{{ $t('monte.stock_semence.localisation') }}</th>
					<th>{{ $t('monte.stock_semence.utilisation') }}</th>
				</tr>
				<tr v-for="stock in stocks" :key="stock.uid">
					<td>{{ stock.dispo }}</td>
					<td>{{ stock.lot }}</td>
					<td>{{ $t('monte.couleur.' + stock.couleur.semencecouleur_code) }}</td>
					<td>{{ stock.localisation }}</td>
					<td>
						<div class="form-group">
							<b-form-input
								v-model="stock.used"
								type="number"
								value="0"
								:max="stock.dispo"
							>
							</b-form-input>
						</div>
					</td>
				</tr>
			</table>

			<div class="text-center">
            	<button @click="submitForm" class="btn btn-primary rounded-pill">
            		<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                    <font-awesome-icon v-else :icon="['far', 'save']" /> {{ $t('global.valider') }}
                </button>
            </div>
		</div>
	</div>
</template>

<script type="text/javascript">
import Actes from '@/mixins/Actes'
import Gynecologie from '@/mixins/Gynecologie'
import StockSemence from '@/mixins/StockSemence'
import Shutter from '@/mixins/Shutter'
import { EventBus } from 'EventBus'
import _groupBy from 'lodash/groupBy'

export default {
	name: 'ConsommationPaillettes',
	mixins: [Actes, Gynecologie, StockSemence, Shutter],
	props: {
		actes_ids: { type: Array, default: () => [] },
		next: { type: Array, default: () => [] },
		horses_ids: { type: Array, default: () => [] },
		from: { type: String, default: () => ''}
	},
	data () {
		return {
			stallion: null,
			mare: null,
			stocks: [],
			stocks_used: [],
			loadingStock: false,
			processing: false,
			date: new Date()
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			this.$sync.force(true)
			this.loadingStock = true
			const acte = await this.getActeFromId(this.actes_ids[0])

			const sms_id = acte.reponse.reponse_hidden_data.find(rep => rep && rep.type == "season_mare_stallion")
			if(!sms_id) this.cancel()
			const sms = await this.loadSeasonMareStallion(sms_id.id)

			this.date = acte.actes_date
			
			this.stallion = sms.horse
			this.mare = sms.mare.horse

			const stocks = await this.loadAllStockLocalDetailsByStallion(this.stallion.horse_id)
			const stocks_grouped = _groupBy(stocks, 'lot')
			this.stocks = []
			for(let i in stocks_grouped) {
				this.stocks.push({
					...stocks_grouped[i][0],
					dispo: stocks_grouped[i].map(j => j.dispo).reduce((sum, a) => sum + a, 0),
					used: 0
				})
			}

			if(this.actes_ids[0] < 0) {
				await this.$sync.force(true, true)
			}
			const acte_id = this.$sync.replaceWithReplicated('horse_actes', this.actes_ids[0])
			this.stocks_used = await this.loadStockUsedByActe(acte_id)
			this.stocks_used.forEach(s => s.cancel = false)

			this.loadingStock = false
		},

		async submitForm() {
			this.processing = true

			const acte_id = this.$sync.replaceWithReplicated('horse_actes', this.actes_ids[0])

			const stock_used = this.stocks.filter(s => s.used != 0)
			const stock_formatted = stock_used.map(s => {
				return {
					id: s.uid,
					stock: s.used,
					acte: acte_id,
					date: this.date
				}
			})

			const stock_canceled = this.stocks_used.filter(s => s.cancel)
			const stock_ids_cancel = stock_canceled.map(s => s.uid)

			if(stock_formatted.length > 0) {
				await this.useSemence(stock_formatted)
			}

			if(stock_ids_cancel.length > 0) {
				await this.cancelUseSemence(stock_ids_cancel)
			}

            if(this.next.length > 0) {
				this.processing = false
				this.successToast("toast.info_modif_succes")
                const triggered = this.next[0]
                this.next.shift()
                this.shutterPanel().close('consommation_paillettes')
                this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.next, this.from)
            } else if(this.$route.name == 'HorseGynecologie') {
				await this.$sync.force(true)
				EventBus.$emit('GynecologieHorse::reload')
				EventBus.$emit('HorseFiche::refreshHorseStatus')
				this.processing = false
				this.successToast("toast.info_modif_succes")
				this.shutterPanel().close('consommation_paillettes')
            } else if(this.$route.name == 'planning') {
				await this.$sync.force(true)
				EventBus.$emit('Planning::refreshTable')
				this.processing = false
				this.successToast("toast.info_modif_succes")
				this.shutterPanel().close('consommation_paillettes')
			} else if (this.$route.params.from == 'acteListe') {
				this.processing = false
				this.successToast("toast.info_modif_succes")
				this.$router.push({ name: 'acteListe'})
			} else { 
				this.processing = false
				this.successToast("toast.info_modif_succes")
				let horse_id = this.$sync.replaceWithReplicated('horse', this.horses_ids[0])
                this.$router.push({ name: 'HorseFichePlanning', params: {horse_id} })
            }
		}
	},

	components: {
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
}

</script>